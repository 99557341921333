/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Icon, Image, Text, View } from "@aws-amplify/ui-react";
export default function GalleryApp(props) {
  const { applications, overrides, ...rest } = props;
  return (
    <View
      width="300px"
      height="400px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(218,222,173,1)"
      {...rest}
      {...getOverrideProps(overrides, "GalleryApp")}
    >
      <Image
        width="300px"
        height="250px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="0px"
        left="0px"
        padding="0px 0px 0px 0px"
        objectFit="cover"
        {...getOverrideProps(overrides, "applicationpic")}
      ></Image>
      <Text
        fontFamily="Inter"
        fontSize="14px"
        fontWeight="700"
        color="rgba(0,0,0,1)"
        lineHeight="16.94318199157715px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        width="194px"
        height="16px"
        gap="unset"
        alignItems="unset"
        position="absolute"
        top="257px"
        left="calc(50% - 97px - 1px)"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children={applications?.name}
        {...getOverrideProps(overrides, "appname")}
      ></Text>
      <Text
        fontFamily="Inter"
        fontSize="13px"
        fontWeight="400"
        color="rgba(0,0,0,1)"
        lineHeight="15.732954025268555px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="267px"
        height="110px"
        gap="unset"
        alignItems="unset"
        position="absolute"
        top="285px"
        left="16px"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children={applications?.description}
        {...getOverrideProps(overrides, "description")}
      ></Text>
      <View
        padding="0px 0px 0px 0px"
        width="300px"
        height="400px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="0px"
        left="0px"
        {...getOverrideProps(overrides, "Border")}
      >
        <Icon
          width="400px"
          height="0px"
          viewBox={{ minX: 0, minY: 0, width: 1, height: 400 }}
          paths={[
            {
              d: "M0 0L400 0L400 -1L0 -1L0 0Z",
              stroke: "rgba(150,179,175,1)",
              fillRule: "nonzero",
              strokeWidth: 1,
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0px"
          bottom="400px"
          left="0px"
          transformOrigin="top left"
          transform="rotate(90deg)"
          {...getOverrideProps(overrides, "left")}
        ></Icon>
        <Icon
          width="400px"
          height="0px"
          viewBox={{ minX: 0, minY: 0, width: 1, height: 400 }}
          paths={[
            {
              d: "M0 0L400 0L400 -1L0 -1L0 0Z",
              stroke: "rgba(150,179,175,1)",
              fillRule: "nonzero",
              strokeWidth: 1,
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0px"
          bottom="400px"
          right="-399px"
          transformOrigin="top left"
          transform="rotate(90deg)"
          {...getOverrideProps(overrides, "right")}
        ></Icon>
        <Icon
          width="300px"
          height="0px"
          viewBox={{ minX: 0, minY: 0, width: 300, height: 1 }}
          paths={[
            {
              d: "M0 0L300 0L300 -1L0 -1L0 0Z",
              stroke: "rgba(150,179,175,1)",
              fillRule: "nonzero",
              strokeWidth: 1,
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="1px"
          left="0px"
          right="0px"
          {...getOverrideProps(overrides, "top")}
        ></Icon>
        <Icon
          width="300px"
          height="0px"
          viewBox={{ minX: 0, minY: 0, width: 300, height: 1 }}
          paths={[
            {
              d: "M0 0L300 0L300 -1L0 -1L0 0Z",
              stroke: "rgba(150,179,175,1)",
              fillRule: "nonzero",
              strokeWidth: 1,
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          bottom="0px"
          left="0px"
          right="0px"
          {...getOverrideProps(overrides, "bottom")}
        ></Icon>
      </View>
    </View>
  );
}
