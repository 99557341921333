import React from 'react';

export default () => {
  return (
    <div>
      <h2>Samples component</h2>
    </div>
  );
};

