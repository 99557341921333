import './App.css';
import './gallery.css';

import {AmplifyProvider} from "@aws-amplify/ui-react";

//  UI Components
import { MyTextComponent } from './ui-components';
import { GalleryLogo } from './ui-components';
import { GalleryLogoMobile } from './ui-components';

//  hamburger menu
import Sidebar from './Sidebar';

//  navbar (includes burger like menu for mobile
//  removes the need for window size functionality
import Navbarmenu from './Navbarmenu';

//  router 
import { BrowserRouter } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';

// routed content
import Home from './Home';
import Samples from './Samples';
import About from './About';

//  use to determine window size
import {useEffect, useState} from 'react';

function BurgerLandingPage() {
  //  window size related functionality
  //  from https://bobbyhadz.com/blog/react-get-window-width-height
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }
//  end of determine window size functionality

  const textCompOverrides = {
    "Vacation Rental": {
      children: "Link to Vacation Rental"
    }
  };

  if (windowSize.innerWidth < 550) {
    return (
      <div className="App" id="outer-container">
        <BrowserRouter>
          <div className="burger-margins">
            <Sidebar outerContainerId={'outer-container'} />
          </div>
          <header>
            <div id="page-wrap">
              <AmplifyProvider>
                <div className="link-image">
                  <GalleryLogoMobile />
                </div>
              </AmplifyProvider>
            </div>
          </header>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/samples" element={<Samples />} />
            <Route path="/about/who" element={<About />} />
            <Route path="/about/what" element={<About />} />
          </Routes>
        </BrowserRouter>
        <footer>
          <div className="centered">
            width: {windowSize.innerWidth} height: {windowSize.innerHeight}
          </div>
        </footer>
      </div>
    );
  }

  //  non-mobile device
  return (
    <div>
      <BrowserRouter>
        <header>
          <AmplifyProvider>
            <div className="nav-area">
              <div className="link-image">
                <GalleryLogo />
              </div>
            </div>
          </AmplifyProvider>
        </header>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/samples" element={<Samples />} />
          <Route path="/about" element={<About />} />
        </Routes>
        <footer>
          <div className="centered">
            width: {windowSize.innerWidth} height: {windowSize.innerHeight}
          </div>
        </footer>
      </BrowserRouter>
    </div>
  );

/*
          <div className="burger-margins">
            <Sidebar outerContainerId={'outer-container'} />
          </div>

      <div>
        <header>
          <AmplifyProvider>
            <div className="nav-area">
              <div className="link-image">
                <GalleryLogo />
              </div>
              <Navbar />
            </div>
          </AmplifyProvider>
        </header>
      </div>
*/
}

function LandingPage() {
  //  window size related functionality
  //  from https://bobbyhadz.com/blog/react-get-window-width-height
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    //  set page <head> meta data
    document.title = "Run on Demand Gallery";
    document.querySelector('meta[name="description"]').setAttribute("content", "Gallery of React application templates");
    document.querySelector('meta[name="keywords"]').setAttribute("content", "react gallery template applications");

    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }
//  end of determine window size functionality

  return (
    <div className="App">
      <BrowserRouter>
        <div>
          <Navbarmenu />
        </div>
        <header>
        </header>
        <Routes>
          <Route path="/" element={<Home windowWidth={windowSize.innerWidth} />} />
          <Route path="/samples" element={<Samples />} />
          <Route path="/about/who" element={<About />} />
          <Route path="/about/what" element={<About />} />
        </Routes>
      </BrowserRouter>
      <footer>
        <div className="centered">
          width: {windowSize.innerWidth} height: {windowSize.innerHeight}
        </div>
      </footer>
    </div>
  );
}

function App() {

  return (
    <div>
      { LandingPage() }
    </div>
  );

}

export default App;
