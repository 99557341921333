import React from 'react';
import { useState, useEffect } from 'react';
import { GalleryAppCollection } from './ui-components';
import { Collection, Card, Heading } from '@aws-amplify/ui-react';

import { Storage } from 'aws-amplify';
import { API } from 'aws-amplify';
import * as queries from './graphql/queries';

export default (props) => {

  const [applications, updateApplications] = useState(null);
  const [image, updateImage] = useState(null);

 useEffect(() => {
    console.log('running getApplications useEffect');

    // Based on https://devtrium.com/posts/async-functions-useeffect,
    // async processes should be done inside of useEffect() and
    // useEffect() execution should only be once after the DOM is
    // rendered

    function handleGetImageError (error) {
      console.log('error getting image', error);
    }

    function setAppImage (appitem, getobjurl) {
      appitem.pictureurl = getobjurl;
    }

    async function getImage(appitem) {
      const imageObj = await Storage.get(appitem.picturekey,
                                         {contentType: 'image/png',
                                          level: 'public'
                                         })
                      .then((response) => setAppImage(appitem, response))
                      .catch((error) => handleGetImageError(error));
    }

    function setApplications (data) {
      let filtereditems = data.listApplications.items.filter(function (a) {
                                                         return !a._deleted
                                                         });
      filtereditems.forEach(item => {
        getImage(item);
      });
      // applications is set only after all data is available
      updateApplications(filtereditems);
    }

    function handleGetApplicationsError (error) {
      console.log('handleGetApplicationsError', error);
      if (error.data)
        console.log('data available', error.data);
    }

    async function getApplications () {
      const allApplications = await API.graphql({ query: queries.listApplications,
                                               authMode: "AWS_IAM" })
                           .then((response) => setApplications(response.data))
                           .catch((error) => handleGetApplicationsError(error));
    }

    // get application data after render
    // with React 18.x, useEffect() will be called twice for some reacon
    getApplications();
  }, []);

  function clickedItem (item) {
    console.log('clicked item', item);
    window.location = item.appurl;
  }

  function buildDisplay () {
    if (!applications)
      return (
        <div>
          <center>Retrieving gallery application information</center>
          <p />
          <ul>
            <li key='1'><a href="https://vacationrental.runondemandgallery.com">Vacation Rental</a></li>
            <li key='2'><a href="https://howtoamplify.runondemandgallery.com">How To Amplify</a></li>
          </ul>
        </div>
      );

    const galleryappcolloverrides = props.windowWidth < 700
      ?  {
          "GalleryAppCollection": {
             type: "list",
             justifyContent: "center"
           }
         }
      :  {
          "GalleryAppCollection": {
            type: "grid",
            templateColumns: "1fr 1fr"
           }
         }

    return (
      <div>
        <GalleryAppCollection 
          items={applications}
          overrides={galleryappcolloverrides}
          overrideItems={({ item, index }) => ({
            onClick: () => clickedItem(item),
            overrides: {
              'applicationpic': {
                src: (item.pictureurl)
              }
            }
          })} />
      </div>
    );
  }

  const display = buildDisplay();

  return (
    <div>
      <p />
      <center><h2>Sample Applications</h2></center>
      <p />
      <div className="collectiondiv" margin="25px">
        {display}
      </div>
    </div>
  );
};

/*
      <Collection
        items={items}
        type="list"
      >
        {(item, index) => (
          <Card
            key={index}
          >
            <Heading>{item.title}</Heading>
          </Card>
      )}
      </Collection>
      <p />
      <GalleryAppCollection 
        items={appdata}
        overrideItems={({ item, index }) => ({
          overrides: { 'description': {
            children: (item.description)
            }
          }
        })}
        />
      {steps ? <HowToStepsCollection
                     items={steps}
                     overrides={stepCollectionOverrides}
                     overrideItems={({ item, index }) => ({
                       width: '350px',
                       overrides: { TextAreaField: {
                                      rows: '10',
                                      label: (
                                        <Text fontWeight="bold"
                                              fontSize={12}
                                              fontFamily="inter">
                                          Step Actions
                                        </Text>
                                      )
                                    },
                                  }
                        })} /> :
*/
