import React from 'react';

export default () => {

  return (
    <div>
      <h2>About component</h2>
    </div>
  );
};

