import React from 'react';
//import { slide as Menu } from 'react-burger-menu';
//import { bubble as Menu } from 'react-burger-menu';
import { elastic as Menu } from 'react-burger-menu';
import './Sidebar.css';

import { Link } from 'react-router-dom';

export default class Sidebar extends React.Component {
  state = {
    menuOpen: false
  };

  handleStateChange(state) {
    this.setState({ menuOpen: state.isOpen });
  }

  closeMenu() {
    this.setState({ menuOpen: false });
  }

  render() {
    return (
      <Menu right isOpen={this.state.menuOpen}
            onStateChange={state => this.handleStateChange(state)}>
        <Link onClick={() => this.closeMenu()} className="bm-item"
              to="/">
          Home 
        </Link>
        <Link onClick={() => this.closeMenu()} className="bm-item"
              to="/samples">
          Samples 
        </Link>
        <div className="bm-parent-item">
          About
        </div>
        <Link onClick={() => this.closeMenu()} className="bm-item"
              to="/about/who">
          &emsp;Who we are 
        </Link>
        <Link onClick={() => this.closeMenu()} className="bm-item"
              to="/about/what">
          &emsp;What we do
        </Link>
      </Menu>
    );
  }
}


